










































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit } from 'vue-property-decorator'
import SubscriptionApi from '../../services/SubscriptionApi'
import moment from 'moment'
import TariffTabCard from './TariffTabCard.vue'
import Sessions from './Sessions.vue'

// [TODO] add it to mixin ?
@Component({
  components: {
    TariffTabCard,
    Sessions
  },
  filters: {
    formatDateTime (val: string) {
      const b = moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY')
      return b
    }
  }
})
export default class Subscriptions extends Vue {
  @Prop({ type: String, default: false }) readonly id!: string
  singleSelect= false
  selected= []
  headers= [
    { text: 'Code', value: 'Id' },
    { text: 'State ', value: 'State' },
    { text: 'Start', value: 'CreatedOn' },
    { text: 'Description', value: 'Description' },
    { text: 'EVCO', value: 'EVCOId' },
    { text: 'Token', value: 'PrintedNumber' }
  ]

  subscriptions = [
    {
      ID: '74946',
      AdminState: 'Active',
      ContractId: '',
      ContractType: 'MSP2CUST',
      CusBlueId: 4812,
      CusUserId: 1,
      DefaultTokenAllId: '6743FB19',
      EndDateTime: '',
      Id: '74946',
      fromid: '',
      fromdescription: 'BlueCorner',
      RelationContactId: '',
      RelationId: '',
      StartDateTime: '2018-04-16 17:15:52',
      SubscriptionReference: '',
      SubscriptionTypeId: 'SUB_STARTER',
      Uci: 'BCO',
      FromDescription: 'BlueCorner',
      ToDescription: 'Francis Lebaigue',
      Tokens: [
        'BE-BCA-406460-5'
      ]
    }
  ]

  tokensData = [
    {
      Id: '03EE79BC',
      Code: '03EE79BC',
      CodeSmallEndian: 'BC79EE03',
      ConcurrentAllowed: 1,
      CreatedOn: '2018-04-16 16:21:12',
      CusId: 13295,
      DateFirstUsed: '',
      Deleted: '',
      Description: ' BC Token',
      EVCOId: 'BEBCOCBC403902',
      ExpirationDate: '2040-12-31 23:59:00',
      IsUsed: '',
      MSPDescription: 'BlueCorner',
      MSPIssuer: 'BlueCorner',
      MarkValue: 'U',
      MspId: 1,
      Number: '',
      NumberOf: '',
      ParentCode: '',
      PrintedNumber: 'BE-BCA-403902-1',
      RoamingProfileId: '',
      State: 'ACTIVE',
      TempPlanonId: 185099,
      TokenType: 'RFID.unknown',
      Uci: 'BCO',
      UpdateDateTime: '2020-08-13 02:33:08',
      UpdateDateTimeDifferential: '',
      UserNr: 1,
      Whitelist: 'Active',
      isPublishToken: 1,
      isRoamingToken: '',
      tempSubscription: 'SUB_STARTER',
      tempTokenGroupType: '245440_TG'
    },
    {
      Id: 'A4732EE6',
      Code: 'A4732EE6',
      CodeSmallEndian: 'E62E73A4',
      ConcurrentAllowed: 1,
      CreatedOn: '2018-04-16 16:16:59',
      CusId: 2098,
      DateFirstUsed: '',
      Deleted: '',
      Description: 'Magali Blue Corner',
      EVCOId: 'BEBCOCBC405701',
      ExpirationDate: '',
      IsUsed: '',
      MSPDescription: 'BlueCorner',
      MSPIssuer: 'BlueCorner',
      MarkValue: 'U',
      MspId: 1,
      Number: '',
      NumberOf: '',
      ParentCode: '',
      PrintedNumber: 'BE-BCA-405701-5',
      RoamingProfileId: '',
      State: 'ACTIVE',
      TempPlanonId: 226620,
      TokenType: 'RFID.unknown',
      Uci: 'BCO',
      UpdateDateTime: '2020-08-13 02:25:41',
      UpdateDateTimeDifferential: '',
      UserNr: 2,
      Whitelist: 'Active',
      isPublishToken: 1,
      isRoamingToken: '',
      tempSubscription: 'SUB_ADDICT',
      tempTokenGroupType: '241797_TG'
    }
  ]

  mounted () {
    this.getDetails()
  }

  async getDetails () {
    // [TODO] Error permission denied
    /* console.log('LOAD SUBSCRIPTIONS')
    const dt = await new SubscriptionApi().details('0')
    console.log(dt) */
    // this.items = dt
    // const dt = await new RelationApi(0).relationTree({}) as any
    // console.log(dt)
    // this.items = dt
  }
}
