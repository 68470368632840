<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <v-flex xs3 v-if="false">
        <v-card flat>
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <b>Code :</b>
              </v-list-item-content>
              <v-list-item-content class="align-end">{{
                tariffData.code | formatLA
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <b>Description :</b>
              </v-list-item-content>
              <v-list-item-content class="align-end">{{
                tariffData.description | formatLA
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <b>Valid since :</b>
              </v-list-item-content>
              <v-list-item-content class="align-end">{{
                tariffData.startdate | formatLA
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="tariffData.onetimefee">
              <v-list-item-content>
                <b>One time fee :</b>
              </v-list-item-content>
              <v-list-item-content class="align-end">{{
                tariffData.onetimefee | formatLA
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="tariffData.monthlyfee">
              <v-list-item-content>
                <b>Monthly fee :</b>
              </v-list-item-content>
              <v-list-item-content class="align-end">{{
                tariffData.monthlyfee | formatLA
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="tariffData.quarterlyfee">
              <v-list-item-content>
                <b>Quarterly fee :</b>
              </v-list-item-content>
              <v-list-item-content class="align-end">{{
                tariffData.quarterlyfee | formatLA
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="tariffData.yearlyfee">
              <v-list-item-content>
                <b>Yearly fee :</b>
              </v-list-item-content>
              <v-list-item-content class="align-end">{{
                tariffData.yearlyfee | formatLA
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="align-end">
                <v-btn text small color="primary" outlined @click="$router.push({name:'History', params: { code: code }})">DETAIL/EDIT</v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-flex>
      <v-flex xs12>
        <v-data-table dense :headers="subRoamHeaders" :items="subTypeEVSEGroupsTab" hide-default-footer hide-default-header color="grey lighten-4" >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>{{tariffData.code}}</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
                <v-btn v-if="false" text small color="primary" @click="$router.push({name:'History', params: { code: code }})">Detail</v-btn>
            </v-toolbar>
          </template>
          <template v-slot:header="props" style="background:grey;">
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" scope="col" :aria-label="header.text" v-for="(header, index) in props.props.headers" :key="index">
                  <span v-if="header.text !== 'Tarrif type' && subRoamTitle[header.text]">
                    <b>{{header.text}}
                    ( Since {{subRoamTitle[header.text] | formatDate}} )</b>
                  </span>
                  <span v-else>
                    <b>{{header.text }}</b>
                  </span>
                </th>
              </tr>
            </thead>
          </template>
            <template v-slot:body="{ items }" style="background:grey;">
              <tbody v-if="items.length === 0">
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td colspan="5" class="pa-5  red--text" > No records found</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="item in items" :key="item.name">
                  <td>{{ item.EV | formatLA}}</td>
                  <td>{{ item.PRIVATE | formatLA}}</td>
                  <td>{{ item.AC | formatLA}}</td>
                  <td>{{ item.DC | formatLA}}</td>
                  <td>{{ item.ROAMING | formatLA}}</td>
                </tr>
              </tbody>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import SubscriptionTypeApi from '../../services/SubscriptionTypeApi'
import moment from 'moment'

export default {
  data () {
    return {
      tariffData: {},
      subRoamHeaders: [
        { text: 'Tarrif type', value: 'EV', sortable: false },
        { text: 'PRIVATE', value: 'PRIVATE', sortable: false },
        { text: 'AC', value: 'AC', sortable: false },
        { text: 'DC', value: 'DC', sortable: false },
        { text: 'Roaming', value: 'ROAMING', sortable: false }
      ],
      subTypeEVSEGroupsObj: {
        startfee: { PRIVATE: '', AC: '', DC: '', ROAMING: '' },
        kwhfee: { PRIVATE: '', AC: '', DC: '', ROAMING: '' },
        rotationfee: { PRIVATE: '', AC: '', DC: '', ROAMING: '' },
        intervalnorotationfeefrom: { PRIVATE: '', AC: '', DC: '', ROAMING: '' },
        intervalnorotationfeeto: { PRIVATE: '', AC: '', DC: '', ROAMING: '' },
        roamingfee: { PRIVATE: '', AC: '', DC: '', ROAMING: '' },
        parkingfee: { PRIVATE: '', AC: '', DC: '', ROAMING: '' },
        intervalnoparkingfeefrom: { PRIVATE: '', AC: '', DC: '', ROAMING: '' },
        intervalnoparkingfeeto: { PRIVATE: '', AC: '', DC: '', ROAMING: '' },
        kwhfeemultiplier1: { PRIVATE: '', AC: '', DC: '', ROAMING: '' },
        kwhfeemultiplier2: { PRIVATE: '', AC: '', DC: '', ROAMING: '' },
        kwhfeemultiplier3: { PRIVATE: '', AC: '', DC: '', ROAMING: '' },
        kwhfeemultiplier4: { PRIVATE: '', AC: '', DC: '', ROAMING: '' },
        kwhfeemultiplier5: { PRIVATE: '', AC: '', DC: '', ROAMING: '' },
        kwhfeemultiplier6: { PRIVATE: '', AC: '', DC: '', ROAMING: '' },
        kwhfeemultiplier7: { PRIVATE: '', AC: '', DC: '', ROAMING: '' },
        daymax: { PRIVATE: '', AC: '', DC: '', ROAMING: '' }
      },
      subRoamTitle: {
        PRIVATE: '',
        AC: '',
        DC: '',
        ROAMING: '',
        BIKE: ''
      },
      subTypeEVSEGroupsTab: []
    }
  },
  mounted () {
    if (this.code) {
      this.getData(this.code)
    } else {
      if (this.obj.SubscriptionTypeEVSEGroups) {
        this.init(this.obj)
      } else {
        if (this.obj.subscription) {
          this.getData(this.obj.subscription)
        } else {
          this.getData(this.obj.tarrifcode)
        }
      }
    }
  },
  methods: {
    async getData (val) {
      console.log('START LOADING TARIFF')
      try {
        const config = {
          type: this.usrType,
          caching: 0
        }
        // TODO ACCESS DENIED
        // const tarrifObj = await new SubscriptionTypeApi().getSubscriptions(val, config)
        const tarrifObj =
          {
            ID: 'BCO||SUB_STARTER',
            AdminState: '',
            Code: 'SUB_STARTER',
            Description: 'Starter',
            EndDate: '',
            MonthlyFee: '',
            OneTimeFee: '',
            QuarterlyFee: '',
            RelationType: 5,
            StartDate: '2010-12-30T23:00:00Z',
            Uci: 'BCO',
            YearlyFee: '',
            StartDateH: 62091,
            SubscriptionTypeEVSEGroups: [
              {
                ID: '12',
                cplogicalgroupid: 0,
                category: '0',
                evtype: 'AC',
                enddatetime: '',
                id: '12',
                landownerid: 0,
                location1: '0',
                location2: '0',
                ownerid: 0,
                startdatetime: '',
                subscriptiontypecode: 'SUB_STARTER',
                tariffcode: 'SUB_STARTER_AC',
                uci: 'BCO',
                Tariff: {
                  ID: 'BCO||SUB_STARTER_AC||65195',
                  calculationtype: 1,
                  code: 'SUB_STARTER_AC',
                  createdon: '2019-08-22 00:00:41',
                  daymax: '',
                  description: 'Starter AC tariff',
                  enddate: '',
                  intervalnoparkingfeefrom: '',
                  intervalnoparkingfeeto: '',
                  intervalnorotationfeefrom: 20,
                  intervalnorotationfeeto: 8,
                  kwhfee: 0.33,
                  kwhfeemultiplier1: '',
                  kwhfeemultiplier2: '',
                  kwhfeemultiplier3: '',
                  kwhfeemultiplier4: '',
                  kwhfeemultiplier5: '',
                  kwhfeemultiplier6: '',
                  kwhfeemultiplier7: '',
                  maximalconsumptionmonthly: '',
                  maximalconsumptionyearly: '',
                  minutesnoparkingfee: '',
                  minutesnorotationfee: '',
                  parkingfee: '',
                  roamingfee: '',
                  rotationfee: 0.0083,
                  startdate: '2019-07-01 00:00:00',
                  startdateh: '65195',
                  startfee: '',
                  state: '',
                  uci: 'BCO',
                  updatedatetime: '2019-09-05 00:00:00'
                }
              }
            ]
          }
        this.init(tarrifObj)
      } catch (e) {
        console.log(e)
      }
    },
    formatEuro (value) {
      let val = 0
      if (value >= 0) {
        val = (value / 1).toFixed(2).replace('.', ',')
        val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' €'
      } else {
        val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' €'
      }
      return val
    },
    init (data) {
      let origKey = ''
      let newO = {}
      let newKey = ''
      for (origKey in data) {
        newKey = origKey.toLowerCase()
        if (newKey !== 'subscriptiontypeevsegroups') {
          newO[newKey] = data[origKey]
        } else {
          newO[origKey] = data[origKey]
        }
      }
      this.tariffData = newO
      data = newO.SubscriptionTypeEVSEGroups
      if (data !== undefined) {
        for (let index = 0; index < data.length; index++) {
          const tariff = data[index].Tariff
          origKey = ''
          newO = {}
          newKey = ''
          for (origKey in tariff) {
            newKey = origKey.toLowerCase()
            newO[newKey] = tariff[origKey]
          }
          let evType = data[index].EvType
          if (evType === undefined) {
            evType = data[index].evtype
          }
          const element = this.subTypeEVSEGroupsObj
          newKey = ''
          for (const field in element) {
            newKey = field.toLowerCase()
            if (newO[field] !== undefined) {
              this.subTypeEVSEGroupsObj[newKey][evType] = newO[field]
            }
          }
          this.subRoamTitle[evType] = tariff.startdate
        }
        this.subTypeEVSEGroupsTab = []
        for (let i in this.subTypeEVSEGroupsObj) {
          let element = this.subTypeEVSEGroupsObj[i]
          if (
            !(element.AC === '' && element.DC === '' && element.ROAMING === '' && element.PRIVATE === '')
          ) {
            if (i === 'rotationfee') {
              element.AC = this.setText(
                element.AC,
                this.subTypeEVSEGroupsObj.intervalnorotationfeefrom.AC,
                this.subTypeEVSEGroupsObj.intervalnorotationfeeto.AC
              )
              element.PRIVATE = this.setText(
                element.PRIVATE,
                this.subTypeEVSEGroupsObj.intervalnorotationfeefrom.PRIVATE,
                this.subTypeEVSEGroupsObj.intervalnorotationfeeto.PRIVATE
              )
              element.DC = this.setText(
                element.DC,
                this.subTypeEVSEGroupsObj.intervalnorotationfeefrom.DC,
                this.subTypeEVSEGroupsObj.intervalnorotationfeeto.DC
              )
              element.ROAMING = this.setText(
                element.ROAMING,
                this.subTypeEVSEGroupsObj.intervalnorotationfeefrom.ROAMING,
                this.subTypeEVSEGroupsObj.intervalnorotationfeeto.ROAMING
              )
            }
            if (i === 'parkingfee') {
              element.AC = this.setText(
                element.AC,
                this.subTypeEVSEGroupsObj.intervalnoparkingfeefrom.AC,
                this.subTypeEVSEGroupsObj.intervalnoparkingfeeto.AC
              )
              element.PRIVATE = this.setText(
                element.PRIVATE,
                this.subTypeEVSEGroupsObj.intervalnoparkingfeefrom.PRIVATE,
                this.subTypeEVSEGroupsObj.intervalnoparkingfeeto.PRIVATE
              )
              element.DC = this.setText(
                element.DC,
                this.subTypeEVSEGroupsObj.intervalnoparkingfeefrom.DC,
                this.subTypeEVSEGroupsObj.intervalnoparkingfeeto.DC
              )
              element.ROAMING = this.setText(
                element.ROAMING,
                this.subTypeEVSEGroupsObj.intervalnoparkingfeefrom.ROAMING,
                this.subTypeEVSEGroupsObj.intervalnoparkingfeeto.ROAMING
              )
            }
            if (
              i !== 'intervalnorotationfeefrom' &&
              i !== 'intervalnorotationfeeto' &&
              i !== 'intervalnoparkingfeeto' &&
              i !== 'intervalnoparkingfeefrom'
            ) {
              if (i === 'kwhfeemultiplier1') {
                this.subTypeEVSEGroupsTab.push({
                  EV: 'Level Fee ( / 15 min) : ',
                  PRIVATE: ' ',
                  AC: ' ',
                  DC: ' ',
                  ROAMING: ' '
                })
              }
              i = this.getType(i)
              if (i !== 'Connection Rate (Per Min)' && i !== 'parkingfee') {
                element.PRIVATE = this.formatEuro(element.PRIVATE)
                element.AC = this.formatEuro(element.AC)
                element.DC = this.formatEuro(element.DC)
                element.ROAMING = this.formatEuro(element.ROAMING)
              }
              let obj = {
                EV: i,
                PRIVATE: element.PRIVATE,
                AC: element.AC,
                DC: element.DC,
                ROAMING: element.ROAMING
              }
              this.subTypeEVSEGroupsTab.push(obj)
              obj = {}
            }
          }
          element = {}
        }
      }
    },
    getType (i) {
      switch (i) {
        case 'rotationfee':
          return 'Connection Rate (Per Min)'
        case 'roamingfee':
          return 'Roaming Fee'
        case 'kwhfeemultiplier1':
          return '   0 - 1 kw'
        case 'kwhfeemultiplier2':
          return '   1 - 2,2 kw'
        case 'kwhfeemultiplier3':
          return '   2,2 - 4 kw'
        case 'kwhfeemultiplier4':
          return '   4 - 8 kw'
        case 'kwhfeemultiplier5':
          return '   8 - 13 kw'
        case 'kwhfeemultiplier6':
          return '   13 - 22 kw'
        case 'kwhfeemultiplier7':
          return '   > 22 kw'
        default:
          return i
      }
    },
    setText (val, from, to) {
      if (val !== '' && from !== '' && to !== '') {
        return (
          this.formatEuro(val) + ' ( From : ' + to + ' - To : ' + from + ' )'
        )
      } else {
        if (val !== '') {
          return (
            this.formatEuro(val)
          )
        }
      }
    }
  },
  filters: {
    formatLA (text) {
      return text !== undefined && text !== '' ? text : ' - '
    },
    formatDate (val) {
      return moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY')
    }
  },
  watch: {
    obj (val) {
      this.init(val)
    },
    code (val) {
      this.getData(val)
    }
  },
  props: ['obj', 'code']
}
</script>
